
import { useFindHeritage } from "@/network/heritageUnit.ts";
import { defineComponent, toRefs, ref, reactive, computed, watch } from "vue";

export default defineComponent({
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    const tabActiveName = ref("1");

    const { visible, id } = toRefs(props.setting as any);
    const baseActiveNames = ref(["1", "2"]);
    const [isLoading, form, find] = useFindHeritage();
    watch(visible, (v) => {
      if (v) {
        tabActiveName.value = "1";
        find(id.value);
      }
    });
    const title = computed(() => {
      return `${form.heritage_Name}(${form.heritage_Code})`;
    });

    return {
      tabActiveName,
      baseActiveNames,
      isLoading,
      title,
      visible,
      form,
    };
  },
});
